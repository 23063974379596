<template>
  <b-overlay
    :show="isLoading"
    rounded="lg"
    opacity="0.6"
    spinner-variant="primary"
  >
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row class="mt-4">
      <b-col cols="12">
        <router-link
          class="btn btn-sm btn-default"
          :to="{ name: UserEbookManagePath.name }"
        >
          <em class="fa fa-backward"></em> Manage User Ebook
        </router-link>
      </b-col>
      <b-col v-if="item">
        <b-form @submit="onSubmit">
          <div class="card mt-4">
            <div class="bg-success p-3">
              <h5 class="card-title mb-0 font-weight-bold">
                Edit User Ebook ({{ this.form.email }})
              </h5>
            </div>
            <div class="card-body">
              <b-row>
                <b-col lg="6" class="mt-4">
                  <b-form-group label="Email *">
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      type="text"
                      step="any"
                      placeholder="email@domain.com"
                      readonly
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" class="mt-4">
                  <b-form-group label="Password *">
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      type="password"
                      step="any"
                      placeholder="*********"
                      maxlength="100"
                      required
                      readonly
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" class="mt-4">
                  <b-form-group label="Gender *">
                    <b-form-select
                      id="gender"
                      v-model="form.gender"
                      track-by="value"
                      :options="genderOptions"
                      required
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg="6" class="mt-4">
                  <b-form-group label="First Name">
                    <b-form-input
                      id="first_name"
                      v-model="form.first_name"
                      type="text"
                      step="any"
                      placeholder="input first name"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" class="mt-4">
                  <b-form-group label="Last Name">
                    <b-form-input
                      id="last_name"
                      v-model="form.last_name"
                      type="text"
                      step="any"
                      placeholder="input last name"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" class="mt-4">
                  <b-form-group label="Phone Number">
                    <b-form-input
                      id="phone_number"
                      v-model="form.phone_number"
                      type="text"
                      step="any"
                      placeholder="input phone number"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" class="mt-4">
                  <b-form-group label="Birthdate">
                    <b-form-input
                      id="birthdate"
                      v-model="form.birthdate"
                      type="date"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col class="mt-4">
                  <b-button variant="primary" type="submit">Update</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { UserEbookManagePath } from '../../router/account';

export default {
  name: 'editUserEbook',
  data() {
    return {
      UserEbookManagePath,
      debounce: null,
      form: {
        user_id: null,
        email: null,
        password: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        gender: null,
        birthdate: null,
      },
      genderOptions: [
        { value: '1', text: 'Male' },
        { value: '2', text: 'Female' },
      ],
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.accessUserEbook.isError,
      isLoading: (state) => state.accessUserEbook.isLoading,
      errorMessage: (state) => state.accessUserEbook.errorMessage,
      successMessage: (state) => state.accessUserEbook.successMessage,
      item: (state) => state.accessUserEbook.item,
    }),
  },
  watch: {
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      Object.assign(this.$data, this.$options.data.call(this));
      this.$router.push(UserEbookManagePath);
    },
    item: function() {
      if (!this.item) {
        this.messageAlert('error', 'not found');
        return;
      }
      if (!Object.keys(this.item).length) return;
      this.setEditUserEbook();
    },
  },
  created() {
    this.form.user_id = this.$route.params.id;
    this.fetchUserEbookById(this.form.user_id);
  },
  methods: {
    ...mapActions('accessUserEbook', ['updateUserEbook', 'fetchUserEbookById']),
    onSubmit(event) {
      event.preventDefault();
      const data = this.form;

      const payload = {
        id: data.user_id,
        field: {
          password: data.password,
          gender: data.gender,
        },
      };
      if (data.first_name) {
        payload.field.first_name = data.first_name;
      }
      if (data.last_name) {
        payload.field.last_name = data.last_name;
      }
      if (data.phone_number) {
        payload.field.phone_number = data.phone_number;
      }
      if (data.birthdate) {
        payload.field.birthdate = data.birthdate;
      }

      this.updateUserEbook(payload);
    },
    setEditUserEbook() {
      const data = this.item;
      this.form.user_id = data?.id;
      this.form.email = data?.email;
      this.form.password = data?.password;
      this.form.gender = data?.gender;
      this.form.first_name = data?.first_name;
      this.form.last_name = data?.last_name;
      this.form.phone_number = data?.phone_number;

      const birthdate = new Date(data?.birthdate);
      if (birthdate.getFullYear() >= 1900) {
        this.form.birthdate = this.formatDate(data?.birthdate);
      } else {
        const defaultBirthdate = new Date(1900, 0, 1);
        this.form.birthdate = this.formatDate(defaultBirthdate);
      }
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    formatDate(tgl) {
      if (tgl) {
        return moment(tgl).format('YYYY-MM-DD');
      }
      return '-';
    },
  },
};
</script>
